import { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import DoneIcon from "@mui/icons-material/Done";
import {
  StyledBtnContainer,
  StyledCard,
  StyledError,
  StyledErrorMessage,
  StyledButton,
  StyledSuccess,
  StyledTitle,
} from "./StyledUploadFile";

interface IProps {
  inputLabel: string;
  title?: string;
  fileId?: string;
  handleOnUpdateFile: (key: any) => void;
  arrayIndex: number;
  errorMessage?: string | null;
  fileType?: string;
}

const UploadFile = ({
  inputLabel,
  title,
  handleOnUpdateFile,
  arrayIndex,
  errorMessage,
  fileType = "file/*",
}: IProps) => {
  const [file, setFile] = useState<null | File>(null);
  const [errorFileSize, setErrorFileSize] = useState<string | null>(null);

  const maxSize = 10 * 1024 * 1024; // (10 MegaBytes)

  const handleCapture = (target: any) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];

        if (file.size > maxSize) {
          setErrorFileSize("Dimensione foto troppo grande");
        } else {
          handleOnUpdateFile(file);
          setFile(file);
          setErrorFileSize(null);
        }
      }
    }
  };

  return (
    <>
      <StyledCard>
        <StyledTitle>{title}</StyledTitle>
        <StyledBtnContainer>
          {file ? (
            <StyledSuccess>
              <DoneIcon />
              <span>File caricato con successo</span>
            </StyledSuccess>
          ) : (
            <>
              <input
                accept={fileType}
                id={`icon-button-file-${arrayIndex}`}
                type="file"
                capture
                onChange={(e) => handleCapture(e.target)}
                style={{ display: "none" }}
              />

              <label htmlFor={`icon-button-file-${arrayIndex}`}>
                <StyledButton>
                  <DownloadIcon />
                  <span>{inputLabel}</span>
                </StyledButton>
              </label>
            </>
          )}
        </StyledBtnContainer>

        {errorFileSize && (
          <StyledError>
            <span>{errorFileSize}</span>
          </StyledError>
        )}
        {errorMessage && (
          <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
        )}
      </StyledCard>
    </>
  );
};

export default UploadFile;
