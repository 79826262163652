import dayjs from "dayjs";

export enum IApimValues {
  mainpolicyapi = "mainpolicyapi",
  vehicle = "vehicle",
  customer = "customer",
  motorQuotation = "motor-quotation",
  utilities = "utilities",
  geo = "geo",
}

export const aniaDataExtraction_base_url = {
  dev: "https://fai-vehicle-api-dev.azurewebsites.net",
  staging: "https://vehicle-api-test.azurewebsites.net",
  prod: "https://fai-vehicle-api-v2.azurewebsites.net",
};

export const apim_base_url = {
  dev: "https://apidev.lumesiainsurance.com",
  staging: "https://apitest.lumesiainsurance.com",
  prod: "https://api.lumesiainsurance.com",
};

export const API_BASE_URL = (value: IApimValues) => {
  return `${
    apim_base_url[process.env.REACT_APP_API_ENV as string]
  }/${value}/api/v1`;
};

interface IfetchCall {
  method: "GET" | "POST" | "PUT" | "DELETE";
  URL: string;
  body?: any;
  cleanResponse?: boolean;
  authToken?: string;
  sendFile?: boolean;
}

const checkHeaders = (sendFile, authToken) => {
  if (sendFile) {
    return { Authorization: `Bearer ${authToken}` };
  }

  return {
    Authorization: `Bearer ${authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  };
};

async function authCall({
  method,
  URL,
  body,
  cleanResponse,
  authToken,
  sendFile,
}: IfetchCall) {
  try {
    const response = await fetch(URL, {
      method,
      headers: checkHeaders(sendFile, authToken) as any,
      body: sendFile ? body : JSON.stringify(body),
    });

    // error response
    if (response.status !== 200) {
      const errorDetails = await response.json();
      return {
        errorDetails,
        error: true,
      };
    }
    // clean success response
    if (cleanResponse) {
      return response;
    }
    // json success response
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error, "error");
    return { error: true };
  }
}

export const fetchCall = async ({
  method,
  URL,
  body,
  cleanResponse,
  sendFile,
}: IfetchCall) => {
  const accessToken = sessionStorage.getItem(`authToken`);
  if (accessToken) {
    return authCall({
      method,
      URL,
      body,
      cleanResponse,
      authToken: accessToken,
      sendFile,
    });
  }
};

export const FaiProductMap = {
  1: "Lumesia Tutela legale",
  2: "Lumesia Assistenza stradale",
  3: "Lumesia Protezione veicoli CVT",
  4: "Lumesia Motor Van & Truck",
  5: "Lumesia Motor Rimorchi e Semirimorchi",
};

export const FormatMoney = (
  value: number,
  n = 0,
  x = 3,
  c = ",",
  s = "."
): string => {
  if (value === undefined || value === null) return "";
  const re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
    num = value.toFixed(Math.abs(n));
  return (c ? num.replace(".", c) : num).replace(
    new RegExp(re, "g"),
    "$&" + (s || ",")
  );
};

export const enum ExportDataStatusEnum {
  empty = "Empty",
  toBeConfirmed = "ToBeConfirmed",
  progress = "InProgress",
  success = "Success",
}

export function calcPercentage(x: number, y: number, fixed = 2) {
  const percent = ((x / y) * 100) as number;
  return Number(percent.toFixed(fixed));
}

export const dateFormat = "DD/MM/YYYY";

export const getProductPath = (product: number) => {
  switch (product) {
    case 1:
      return "LegalProtection";
    case 2:
      return "RoadsideAssistance";
    case 3:
      return "Cvt";
    case 4:
      return "Motor";
    case 5:
      return "Motor";
    default:
      return null;
  }
};

export const getfractionation = (fractionation: number) => {
  switch (fractionation) {
    case 4:
      return "Semestrale";
    case 6:
      return "Annuale";
    default:
      return null;
  }
};

export const getPolicyState = (policyState: number) => {
  switch (policyState) {
    case 1:
      return "Generata";
    case 2:
      return "Valida";
    case 3:
      return "Scaduta";
    case 4:
      return "Chiusa";
    default:
      return null;
  }
};

export const sendDateFormat = "YYYY/MM/DD";
export const readDateFormat = "DD/MM/YYYY";

export const downloadFile = (blob, name) => {
  const anchorElement = document.createElement("a");
  document.body.appendChild(anchorElement);
  anchorElement.style.display = "none";
  const fileURL = window.URL.createObjectURL(blob);
  anchorElement.href = fileURL;
  anchorElement.download = name;
  anchorElement.click();
  window.URL.revokeObjectURL(fileURL);
};

export const phoneRegExp = /^((00|\+)39[\. ]??)??3\d{2}[\. ]??\d{6,7}$/;

export const getDateJsValidation = (value) => {
  if (value === null) {
    return false;
  }
  const firstLetter = value.charAt(0);
  if (firstLetter === "0") {
    return false;
  }
  return dayjs(value).isValid();
};

export const enum TypeEnumsValues {
  aniaVehicleType = "EAniaVehicleType",
  beneVehicleType = "EBeneVehicleType",
  powerSupply = "EPowerSupply",
  antiTheftDevice = "EAntiTheftDevice",
  transportType = "EVehicleUsage",
  dangerousGoodsType = "EDangerousGoodsType",
  fractionationType = "EFractionationType",
  specialUseType = "ESpecialUseType",
  legalForm = "ELegalForm",
  genderType = "EGenderType",
}

export const enum TypeGeoValues {
  provinces = "GetProvinces",
  cityByProvinces = "GetCitiesByProvinceCode",
  nations = "GetNations",
}

export interface IUse {
  data: null | any;
  error: boolean;
  loading: boolean;
}
