import { FormHelperText, MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import Select from "@mui/material/Select";
import { IUse, TypeGeoValues } from "Utils";
import { useGeo } from "../../hooks";
import CircularProgress from "@mui/material/CircularProgress";

const StyledSelectError = styled("div")(() => ({
  ".MuiFormHelperText-root": {
    bottom: "10px",
  },
}));

const GetMenuItemsFromGeo = (type: TypeGeoValues) => {
  const { data, error, loading }: IUse = useGeo(type);
  if (error) {
    return <></>;
  }
  if (loading) {
    return <CircularProgress />;
  }
  if (data) {
    return data.map((item: { [key: string]: string }, index: number) => {
      return (
        <MenuItem value={item.initials} key={index}>
          {item.initials}
        </MenuItem>
      );
    });
  }
  return <></>;
};

interface IProps {
  value: string | null | undefined;
  onChange: (value: string | null) => void;
  error: boolean;
  errorMessage: string | undefined;
  disabled: boolean;
  startCall?: boolean | null | undefined;
  label: string;
}

const ProvinceSelect = ({
  value,
  onChange,
  error,
  errorMessage,
  disabled,
  startCall = true,
  label,
}: IProps) => {
  return (
    <>
      <Select
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        error={error}
        disabled={disabled}
        label
      >
        {startCall && GetMenuItemsFromGeo(TypeGeoValues.provinces)}
      </Select>
      {error && (
        <StyledSelectError>
          <FormHelperText>{errorMessage}</FormHelperText>
        </StyledSelectError>
      )}
    </>
  );
};

export default ProvinceSelect;
