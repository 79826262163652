import { useMemo, useState } from "react";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import { Title } from "react-admin";
import {
  Grid,
  Card,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { toast } from "react-toastify";
import {
  FaiProductMap,
  FormatMoney,
  readDateFormat,
  getfractionation,
  getPolicyState,
  fetchCall,
  API_BASE_URL,
  IApimValues,
  downloadFile,
} from "../../Utils";

const StyledTitle = styled("div")(() => ({
  fontSize: "15px",
  paddingBottom: "10px",
  fontWeight: "600",
  borderBottom: "1px solid",
  width: "fit-content",
}));

const QuotesDetail = ({ quotesData }) => {
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  // const showBankTransfer = useMemo(() => {
  //   if (quotesData?.bankTransferInfo) {
  //     return true;
  //   }
  //   return false;
  // }, [quotesData?.bankTransferInfo]);

  const handleToDownload = (uri: string, fileName: string) => {
    setDownloadLoading(true);
    fetchCall({
      method: "GET",
      URL: `${API_BASE_URL(
        IApimValues.mainpolicyapi
      )}/Policies/download-document?relativeUrl=${uri}`,
      cleanResponse: true,
    })
      .then((response) => response.blob())
      .then((blob) => {
        downloadFile(blob, `${fileName}.pdf`);
        setDownloadLoading(false);
      })
      .catch(() => {
        setDownloadLoading(false);
        toast.error("Si è verificato un errore", {
          position: "bottom-right",
          type: "error",
          autoClose: 5000,
          theme: "colored",
        });
      });
  };

  return (
    <>
      <Title title={`Preventivo N° ${quotesData.policyNumber}`} />
      <Card
        sx={{
          position: "relative",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={2} sx={{ margin: 2 }}>
          <Grid item xs={12} sm={11}>
            <StyledTitle>Contraente</StyledTitle>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color={"primary"}>
              Nome Contraente
            </Typography>
            <Typography gutterBottom>{quotesData.businessName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color={"primary"}>
              Indirizzo Legale
            </Typography>
            <Typography gutterBottom>{quotesData.legalAddress}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color={"primary"}>
              Codice fiscale
            </Typography>
            <Typography gutterBottom>{quotesData.vatNumber}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ margin: 2 }}>
          <Grid item xs={12} sm={11}>
            <StyledTitle>Polizza</StyledTitle>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color={"primary"}>
              Prodotto
            </Typography>
            <Typography gutterBottom>{`${
              FaiProductMap[quotesData.productType]
            }`}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color={"primary"}>
              Targa
            </Typography>
            <Typography gutterBottom>{quotesData.plate}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color={"primary"}>
              Data di decorrenza
            </Typography>
            <Typography gutterBottom>
              {dayjs(quotesData.effectiveDate).format(readDateFormat)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color={"primary"}>
              Data di scadenza
            </Typography>
            <Typography gutterBottom>
              {dayjs(quotesData.expiringDate).format(readDateFormat)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color={"primary"}>
              Premio annuale
            </Typography>
            <Typography gutterBottom>{`${FormatMoney(
              Number(quotesData.annualPremium),
              2
            )}€`}</Typography>
          </Grid>
          {getfractionation(quotesData.fractionation) !== null && (
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color={"primary"}>
                Frazionamento
              </Typography>
              {getfractionation(quotesData.fractionation)}
            </Grid>
          )}
          {quotesData?.warranties && quotesData?.warranties.length > 0 && (
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color={"primary"}>
                Garanzie
              </Typography>
              {quotesData?.warranties?.map((item) => {
                return item;
              })}
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color={"primary"}>
              Firma
            </Typography>
            {quotesData.isSigned ? "Si" : "No"}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color={"primary"}>
              Modello
            </Typography>
            {quotesData.vehicleModel}
          </Grid>
          {getPolicyState(quotesData?.policyState) !== null && (
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color={"primary"}>
                Stato Polizza
              </Typography>
              {getPolicyState(quotesData?.policyState)}
            </Grid>
          )}
        </Grid>

        {quotesData?.documents && quotesData?.documents.length > 0 && (
          <Grid container spacing={2} sx={{ margin: 2 }}>
            <Grid item xs={12} sm={11}>
              <StyledTitle>Documenti</StyledTitle>
            </Grid>
            {downloadLoading ? (
              <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
                <CircularProgress />
              </Grid>
            ) : (
              quotesData?.documents?.map((doc) => {
                return (
                  <Grid item xs={12} sm={6}>
                    <Button
                      startIcon={<DownloadIcon />}
                      color="primary"
                      variant="outlined"
                      sx={{ textAlign: "left" }}
                      onClick={() => {
                        handleToDownload(doc.uri, doc.documentName);
                      }}
                    >
                      {doc.documentName}
                    </Button>
                  </Grid>
                );
              })
            )}
          </Grid>
        )}
      </Card>
    </>
  );
};

export default QuotesDetail;
