import { styled } from "@mui/system";

const StyledProgress = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "80px",
  paddingBottom: "80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  color: theme.palette.primary.main,

  span: {
    marginLeft: "10px",
  },
}));

const StyledCalcelRequest = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: "20px",
  right: "20px",
  cursor: "pointer",
  fontSize: "13px",
  lineHeight: "13px",
  fontWeight: "600",
  color: theme.palette.warning.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  span: {
    marginLeft: "5px",
  },
}));

const StyledInfoProgress = styled("div")(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "14px",
  paddingTop: "30px",
  color: theme.palette.gray.main,
}));

const StyledExample = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: "20px",
  right: "20px",
  cursor: "pointer",
  fontSize: "13px",
  fontWeight: "600",
  color: theme.palette.secondary.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  a: {
    marginLeft: "5px",
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
}));

const StyledText = styled("div")(() => ({
  fontSize: "15px",
  lineHeight: "24px",
}));

const StyledReminderTitle = styled("div")(({ theme }) => ({
  fontSize: "15px",
  lineHeight: "20px",
  fontWeight: "bold",
  color: theme.palette.primary.main,
  paddingTop: "20px",
}));

export {
  StyledProgress,
  StyledCalcelRequest,
  StyledInfoProgress,
  StyledExample,
  StyledText,
  StyledReminderTitle,
};
