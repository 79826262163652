import { styled } from "@mui/system";

const StyledCard = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "40px",
  paddingBottom: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const StyledBtnContainer = styled("div")(({ theme }) => ({
  justifyContent: "center",
  display: "flex",
  alignItems: "end",
  paddingBottom: "20px",
  paddingTop: "20px",
}));

const StyledError = styled("div")(({ theme }) => ({
  textAlign: "center",
  fontSize: "14px",
  color: theme.palette.error.main,
}));

const StyledErrorMessage = styled("div")(({ theme }) => ({
  textAlign: "center",
  fontSize: "14px",
  color: theme.palette.error.main,
}));

const StyledButton = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "5px",
  padding: "10px",
  backgroundColor: "white",
  color: theme.palette.primary.main,
  cursor: "pointer",

  span: {
    fontSize: "16px",
    fontWeight: "600",
    marginLeft: "10px",
  },
}));

const StyledSuccess = styled("div")(({ theme }) => ({
  color: theme.palette.success.main,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",

  span: {
    fontSize: "16px",
    fontWeight: "600",
    marginLeft: "10px",
  },
}));

interface IProps {
  paddingBottom?: boolean;
}

const StyledTitle = styled("div")(({ paddingBottom }: IProps) => ({
  fontSize: "16px",
  fontWeight: "600",
  paddingBottom: paddingBottom ? "30px" : "10px",
}));

export {
  StyledCard,
  StyledBtnContainer,
  StyledError,
  StyledErrorMessage,
  StyledButton,
  StyledSuccess,
  StyledTitle,
};
