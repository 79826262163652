import { useMsal } from "@azure/msal-react";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

const StyledLoginWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: "100%",
  height: "100vh",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",

  svg: {
    height: "100px",
    width: "100px",
    fill: "white",
    marginBottom: "20px",
  },
}));

const LoginButton = styled(Button)(() => ({
  color: "white",
  fontSize: "18px",
  borderColor: "white",

  "&:hover": {
    borderColor: "white",
  },
}));

const Login = () => {
  const { instance } = useMsal();

  const initializeSignIn = () => {
    instance.loginRedirect();
  };

  return (
    <StyledLoginWrapper>
      <AdminPanelSettingsIcon />
      <LoginButton size="large" variant="outlined" onClick={initializeSignIn}>
        Accedi
      </LoginButton>
    </StyledLoginWrapper>
  );
};

export default Login;
