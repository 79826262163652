import { useState } from "react";
import { useNavigate } from "react-router";
import { TopToolbar } from "react-admin";
import { toast } from "react-toastify";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Button, CircularProgress, Box } from "@mui/material";
import { fetchCall, IApimValues, API_BASE_URL } from "../../Utils";
import BankTransfer from "Components/BankTransfer/BankTransfer";

interface IPrpos {
  showBankTransfer: boolean;
  policyNumber: string;
}

const ProductDetailsListActions = ({
  showBankTransfer,
  policyNumber,
}: IPrpos) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnTransferPaid = () => {
    setLoading(true);
    const dataObj = {
      //   policyNumber: queryStringParams.id,
      //   titleId: data.titleId,
      //   amount: data?.bankTransferInfo?.totalAmountToBePaid,
      //   navisionId,
    };
    fetchCall({
      method: "POST",
      body: dataObj,
      URL: `${API_BASE_URL(
        IApimValues.vehicle
      )}/MidtermPayment/BankTransfer/ConfirmPayment`,
    })
      .then((data) => {
        if (data.error) {
          setLoading(false);
          toast.error(
            data.errorDetails?.detail
              ? `${data.errorDetails?.detail}`
              : "Si è verificato un errore",
            {
              position: "bottom-right",
              type: "error",
              autoClose: 5000,
              theme: "colored",
            }
          );
        } else {
          toast.success("L'azione è andata a buon fine", {
            position: "bottom-right",
            type: "success",
            autoClose: 5000,
            theme: "colored",
          });
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <CircularProgress size={25} />
      </Box>
    );
  }

  return (
    <Box>
      <TopToolbar>
        <BankTransfer
          showBankTransfer={showBankTransfer}
          handleOnTransferPaid={handleOnTransferPaid}
          policyNumber={policyNumber}
        />
        <Button
          startIcon={<ArrowBack />}
          color="primary"
          onClick={() => navigate(-1)}
        >
          Indietro
        </Button>
      </TopToolbar>
    </Box>
  );
};

export default ProductDetailsListActions;
