import { FormHelperText, MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import Select from "@mui/material/Select";
import { IUse, TypeGeoValues } from "Utils";
import { useGeo } from "../../hooks";
import CircularProgress from "@mui/material/CircularProgress";

const StyledSelectError = styled("div")(() => ({
  ".MuiFormHelperText-root": {
    bottom: "10px",
  },
}));

const GetMenuItemsFromGeo = (type: TypeGeoValues, provincesCode?) => {
  const { data, error, loading }: IUse = useGeo(type, provincesCode);
  if (error) {
    return <></>;
  }
  if (loading) {
    return <CircularProgress />;
  }
  if (data) {
    return data.map((item: { [key: string]: string }, index: number) => {
      return (
        <MenuItem value={item.id} key={index}>
          {item.name}
        </MenuItem>
      );
    });
  }
  return <></>;
};

interface IProps {
  value: string | null | undefined;
  onChange: (value: string | null) => void;
  error: boolean;
  errorMessage: string | undefined;
  province: string | null | undefined;
  disabled: boolean;
  setCityName?: (value: string) => void;
  startCall?: any;
  label: string;
}

const CitiesSelect = ({
  value,
  onChange,
  error,
  errorMessage,
  province,
  disabled,
  setCityName,
  startCall = true,
  label,
}: IProps) => {
  return (
    <>
      <Select
        value={value}
        onChange={(e, child: any) => {
          onChange(e.target.value);
          setCityName && setCityName(child?.props?.children);
        }}
        error={error}
        disabled={disabled}
        label
      >
        {startCall &&
          GetMenuItemsFromGeo(TypeGeoValues.cityByProvinces, province)}
      </Select>
      {error && (
        <StyledSelectError>
          <FormHelperText>{errorMessage}</FormHelperText>
        </StyledSelectError>
      )}
    </>
  );
};

export default CitiesSelect;
