import { useEffect, useState, useRef } from "react";
import { Title } from "react-admin";
import { useMsal } from "@azure/msal-react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { Card, Box, Typography } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { aniaDataExtraction_base_url, calcPercentage } from "../../Utils";
import {
  StyledProgress,
  StyledCalcelRequest,
  StyledInfoProgress,
} from "./StyledDataExtraction";

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number }
) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

interface IProps {
  handleOnCancelClick: () => void;
  handleOnDataExtractionCompleted: () => void;
  progressData: any;
}

const DataExtractionProgress = ({
  handleOnCancelClick,
  handleOnDataExtractionCompleted,
  progressData,
}: IProps) => {
  const render = useRef(false);
  const { accounts } = useMsal();
  const [statusConnection, setStatusConnection] = useState<any>(null);
  const [progress, setProgress] = useState<number>(0);
  const [workedRecordsCount, setWorkedRecordsCount] = useState<number>(0);
  const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);

  const userEmail = accounts[0].username;

  const handleOnDisconnectAndCancel = () => {
    statusConnection.stop().then(() => {
      handleOnCancelClick();
    });
  };

  const sendConnection = async () => {
    try {
      const conn = new HubConnectionBuilder()
        .withUrl(
          `${
            aniaDataExtraction_base_url[process.env.REACT_APP_API_ENV as string]
          }/AniaDataExtractionHub?email=${userEmail}`
        )
        .build();

      conn.on("WorkCompleted", () => {
        conn.stop().then(() => {
          handleOnDataExtractionCompleted();
        });
      });

      conn.on("WorkInProgress", (data) => {
        const percentage: number = calcPercentage(
          data.workedRecordsCount,
          data.totalRecordsCount
        );
        setProgress(percentage);
        setWorkedRecordsCount(data.workedRecordsCount);
        setTotalRecordsCount(data.totalRecordsCount);
      });

      await conn.start().then(() => setStatusConnection(conn));
    } catch (err) {
      console.error(err, "error");
    }
  };

  useEffect(() => {
    if (progressData) {
      const percentage: number = calcPercentage(
        progressData.workedRecordsCount,
        progressData.totalRecordsCount
      );
      setProgress(percentage);
      setWorkedRecordsCount(progressData.workedRecordsCount);
      setTotalRecordsCount(progressData.totalRecordsCount);
    }
  }, [progressData]);

  useEffect(() => {
    if (!render.current) {
      sendConnection();
    }
    render.current = true;
  }, []);

  return (
    <>
      <Title title={"Estrazione dati ANIA"} />
      <Card sx={{ marginTop: "20px", position: "relative" }}>
        <StyledCalcelRequest onClick={() => handleOnDisconnectAndCancel()}>
          <DeleteForeverIcon />
          <span>Annulla richiesta</span>
        </StyledCalcelRequest>
        <StyledProgress>
          <span>Stiamo gestendo la tua richiesta...</span>
          <Box sx={{ width: "50%", paddingTop: "30px" }}>
            {progress === 0 ? (
              <LinearProgress />
            ) : (
              <LinearProgressWithLabel value={progress} />
            )}
          </Box>
          <StyledInfoProgress>
            {workedRecordsCount === 1
              ? `È stata lavorata ${workedRecordsCount} targa su ${totalRecordsCount}`
              : `Sono state lavorate ${workedRecordsCount} targhe su ${totalRecordsCount}`}
          </StyledInfoProgress>
        </StyledProgress>
      </Card>
    </>
  );
};

export default DataExtractionProgress;
