import * as yup from "yup";

export const validationSchema = yup
  .object()
  .shape({
    user: yup.string().nullable().required("Campo obbligatorio"),
    proposalId: yup.string().nullable().required("Campo obbligatorio"),
    isCommercialQuote: yup.boolean().nullable().required("Campo obbligatorio"),
    IsNoCirculationDeclarationSupplied: yup
      .boolean()
      .nullable()
      .required("Campo obbligatorio"),
    newRegisteredVehicle: yup
      .boolean()
      .nullable()
      .required("Campo obbligatorio"),
  })
  .required();
