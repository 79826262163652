import { useState, useEffect, useRef } from "react";
import { Title } from "react-admin";
import { toast } from "react-toastify";
import { Card, Button, Stack } from "@mui/material";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { Loader, UploadFile } from "../../Components";
import {
  StyledCard,
  StyledTitle,
} from "../../Components/UploadFile/StyledUploadFile";
import {
  ExportDataStatusEnum,
  fetchCall,
  API_BASE_URL,
  IApimValues,
} from "../../Utils";
import { StyledExample, StyledText } from "./StyledDataExtraction";

interface IProps {
  handleOnConfirmExtratData: () => void;
  handleOnCancelClick: () => void;
  status: ExportDataStatusEnum | null;
  handleOnUpdateProgressData: (data) => void;
}

const DataExtractionEmpty = ({
  handleOnConfirmExtratData,
  handleOnCancelClick,
  status,
  handleOnUpdateProgressData,
}: IProps) => {
  const render = useRef(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [platesNumbers, setPlatesNumbers] = useState<number | null>(null);

  const handleOnUpdateFile = async (file) => {
    setFileLoading(true);
    const fileData = new FormData();
    fileData.append("file", file);

    fetchCall({
      method: "POST",
      URL: `${API_BASE_URL(IApimValues.vehicle)}/AniaDataExtraction/Upload`,
      body: fileData,
      sendFile: true,
    })
      .then((data) => {
        if (data.error) {
          setFileLoading(false);
          toast.error(
            data.errorDetails?.detail
              ? `${data.errorDetails?.detail}`
              : "Si è verificato un errore",
            {
              position: "bottom-right",
              type: "error",
              autoClose: 5000,
              theme: "colored",
            }
          );
        } else {
          setPlatesNumbers(data);
          setFileLoading(false);
        }
      })
      .catch(() => {
        setFileLoading(false);
      });
  };

  const handleOnConfirm = () => {
    setLoading(true);
    fetchCall({
      method: "POST",
      URL: `${API_BASE_URL(IApimValues.vehicle)}/AniaDataExtraction/Confirm`,
      cleanResponse: true,
    })
      .then((data) => {
        if (data.error) {
          setLoading(false);
          toast.error(
            data.errorDetails?.detail
              ? `${data.errorDetails?.detail}`
              : "Si è verificato un errore",
            {
              position: "bottom-right",
              type: "error",
              autoClose: 5000,
              theme: "colored",
            }
          );
        } else {
          handleOnConfirmExtratData();
          handleOnUpdateProgressData({
            workedRecordsCount: 0,
            remainingRecordsCount: 0,
            totalRecordsCount: platesNumbers,
          });
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (status === ExportDataStatusEnum.toBeConfirmed) {
      if (!render.current) {
        setFileLoading(true);
        fetchCall({
          method: "GET",
          URL: `${API_BASE_URL(
            IApimValues.vehicle
          )}/AniaDataExtraction/PlatesToBeConfirmed`,
        })
          .then((data) => {
            if (data.error) {
              setFileLoading(false);
              toast.error(
                data.errorDetails?.detail
                  ? `${data.errorDetails?.detail}`
                  : "Si è verificato un errore",
                {
                  position: "bottom-right",
                  type: "error",
                  autoClose: 5000,
                  theme: "colored",
                }
              );
            } else {
              setPlatesNumbers(data);
              setFileLoading(false);
            }
          })
          .catch(() => {
            setFileLoading(false);
          });
      }
      render.current = true;
    }
  }, [status]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Title title={"Estrazione dati ANIA"} />
      <Card
        sx={{
          marginTop: "20px",
          position: "relative",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <StyledExample>
          <ArticleOutlinedIcon />
          <a
            href={`${process.env.PUBLIC_URL}/ania-template-example.xlsx`}
            download
          >
            Scarica il file di esempio
          </a>
        </StyledExample>
        <StyledText>
          Per poter estrarre rapidamente tutte le informazioni utili dal
          database ANIA scarica il tamplate cliccando su “Scarica il file di
          esempio”, inserisci la lista delle targhe da analizzare e salva il
          file. Appena salvato sottomettilo cliccandolo su “Carica file” e
          conferma il numero delle targhe da analizzare. Lanciata la richiesta
          attendi il completamento e avrai tutti i dati sia a video sia tramite
          download di un file completo.
          <br />
          Nota bene: all’aumento del numero delle targhe aumenterà il tempo di
          elaborazione, ma potrai tranquillamente chiudere la pagina o lasciarla
          aperta in attesa e poi tornare a verificare il completamento! Nessun
          dato verrà perso.
        </StyledText>
        {fileLoading && <Loader />}
        {platesNumbers && (
          <StyledCard>
            <StyledTitle paddingBottom>
              Stai richiedendo l'analisi di {platesNumbers} targhe
            </StyledTitle>
            <Stack spacing={2} direction="row">
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleOnConfirm()}
              >
                Conferma
              </Button>
              <Button color="primary" onClick={() => handleOnCancelClick()}>
                Annulla
              </Button>
            </Stack>
          </StyledCard>
        )}
        {!fileLoading && !platesNumbers && (
          <UploadFile
            arrayIndex={1}
            inputLabel={"Carica File"}
            handleOnUpdateFile={(file) => handleOnUpdateFile(file)}
            errorMessage={null}
            title={"Carica il file Excel che vuoi processare"}
            fileType={".xls,.xlsx"}
          />
        )}
      </Card>
    </>
  );
};

export default DataExtractionEmpty;
