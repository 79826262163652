import { UserMenu } from "react-admin";
import { useMsal } from "@azure/msal-react";
import {
  Box,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  Divider,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Logout from "../Auth/Logout";

const CustomUserMenu = (props: any) => {
  const { accounts } = useMsal();

  return (
    <UserMenu {...props}>
      <Box>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem>
            <ListItemIcon>
              <PersonIcon style={{ fill: "primary" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ color: "primary" }}>
                  {accounts && accounts[0]?.name}
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Logout />
          </ListItem>
        </List>
      </Box>
    </UserMenu>
  );
};

export default CustomUserMenu;
