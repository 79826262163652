import { useMsal } from "@azure/msal-react";
import { styled } from "@mui/system";

const StyledLogOutContainer = styled("div")(() => ({
  width: "100%",
  textAlign: "right",
  cursor: "pointer",
  fontSize: "15px",
  paddingTop: "10px",
}));

const Logout = () => {
  const { instance } = useMsal();

  const handleOnSignOut = () => {
    instance.logoutRedirect();
  };

  return (
    <StyledLogOutContainer onClick={handleOnSignOut}>
      Log out
    </StyledLogOutContainer>
  );
};

export default Logout;
