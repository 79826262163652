import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";

const BankTransfer = ({
  showBankTransfer,
  handleOnTransferPaid,
  policyNumber,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOnConfirmClick = () => {
    setOpenModal(true);
  };

  if (showBankTransfer) {
    return (
      <>
        <Button
          startIcon={<AssuredWorkloadIcon />}
          color="primary"
          onClick={handleOnConfirmClick}
        >
          Conferma incasso bonifico
        </Button>

        {openModal && (
          <Dialog
            onClose={handleCloseModal}
            aria-labelledby="customized-dialog-title"
            open={openModal}
          >
            <DialogTitle>Attenzione!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Sei sicuro di voler confermare l'incasso del bonifico <br />
                per la Polizza N° {policyNumber}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Annulla
              </Button>
              <Button
                onClick={() => {
                  handleCloseModal();
                  handleOnTransferPaid();
                }}
                variant="contained"
                style={{ color: "white" }}
              >
                Conferma
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }

  return null;
};

export default BankTransfer;
