import { useState, useMemo } from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { fontSize, styled } from "@mui/system";
import { Title } from "react-admin";
import {
  TextField,
  FormHelperText,
  Autocomplete,
  Box,
  FormControlLabel,
  Checkbox,
  Skeleton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { validationSchema } from "./validationSchema";
import { fetchCall, API_BASE_URL, IApimValues } from "../../Utils";
import { Loader } from "../../Components";

const StyledImportQuoteform = styled("div")(() => ({
  paddingTop: "50px",
  paddingBottom: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "400px",
  width: "100%",
  margin: "0 auto",

  ".MuiFormControl-root": {
    width: "100%",
  },

  ".MuiFormControlLabel-root": {
    ".MuiTypography-root": {
      fontSize: "15px",
    },
  },
}));

const ImportQuote = () => {
  const navigate = useNavigate();

  const [getCustomersLoading, setGetCustomersLoading] =
    useState<boolean>(false);
  const [newRegisteredVehicleDisabled, setNewRegisteredVehicleDisabled] =
    useState<boolean>(false);
  const [
    IsNoCirculationDeclarationSuppliedDisabled,
    setIsNoCirculationDeclarationSuppliedDisabled,
  ] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<any[]>([]);
  const [showWarningModal, setShowWarningModal] = useState({
    show: false,
    content: "",
  });

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      user: undefined,
      proposalId: undefined,
      isCommercialQuote: false,
      IsNoCirculationDeclarationSupplied: false,
      newRegisteredVehicle: false,
    },
    resolver: yupResolver(validationSchema),
  });

  const getCustomers = (value) => {
    setGetCustomersLoading(true);
    fetchCall({
      method: "GET",
      URL: `${API_BASE_URL(
        IApimValues.customer
      )}/Customer/GetCustomersByText?searchText=${value}&recordLimit=${20}`,
    })
      .then((data) => {
        if (data.error) {
          toast.error(
            data.errorDetails?.detail
              ? `${data.errorDetails?.detail}`
              : "Si è verificato un errore",
            {
              position: "bottom-right",
              type: "error",
              autoClose: 5000,
              theme: "colored",
            }
          );
          setGetCustomersLoading(false);
        } else {
          setUsersList(data);
          setGetCustomersLoading(false);
        }
      })
      .catch(() => {
        toast.error("Si è verificato un errore", {
          position: "bottom-right",
          type: "error",
          autoClose: 5000,
          theme: "colored",
        });
        setGetCustomersLoading(false);
      });
  };

  const debouncedCustomers = useMemo(() => debounce(getCustomers, 500), []);

  const onSubmit = (data) => {
    setLoading(true);
    const dataObj = {
      navisionId: data.user,
      beneProposalId: data.proposalId,
      isNoCirculationDeclarationSupplied:
        data.IsNoCirculationDeclarationSupplied,
      isNewRegistration: data.newRegisteredVehicle,
    };

    fetchCall({
      method: "POST",
      URL: `${API_BASE_URL(
        IApimValues.motorQuotation
      )}/QualifiedQuote/ValidateImportData`,
      body: dataObj,
    })
      .then((response) => {
        if (response.error) {
          if (response.errorDetails.type === "Import-warning-exception") {
            setShowWarningModal({
              show: true,
              content: response.errorDetails.detail,
            });
          } else {
            toast.error(
              response.errorDetails?.detail
                ? `${response.errorDetails?.detail}`
                : "Si è verificato un errore",
              {
                position: "bottom-right",
                type: "error",
                autoClose: 5000,
                theme: "colored",
              }
            );
          }
          setLoading(false);
          return false;
        }

        if (!response.success) {
          toast.error(
            response?.errorMessage
              ? `${response?.errorMessage}`
              : "Si è verificato un errore",
            {
              position: "bottom-right",
              type: "error",
              autoClose: 5000,
              theme: "colored",
            }
          );
          setLoading(false);
          return false;
        }

        if (response.success) {
          navigate(
            `/admin-importa-preventivo-dati?navisionId=${data.user}&beneProposalId=${data.proposalId}&isCommercialQuote=${data.isCommercialQuote}&IsNoCirculationDeclarationSupplied=${data.IsNoCirculationDeclarationSupplied}&newRegisteredVehicle=${data.newRegisteredVehicle}`
          );
          setLoading(false);
          return false;
        }
      })
      .catch(() => {
        toast.error("Si è verificato un errore", {
          position: "bottom-right",
          type: "error",
          autoClose: 5000,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Title title={"Importa Preventivo"} />
      <Card
        sx={{
          marginTop: "20px",
          position: "relative",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <StyledImportQuoteform>
            <form>
              <Grid container spacing={2} sx={{ paddingBottom: "40px" }}>
                <Grid size={100}>
                  <Controller
                    name="user"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <Autocomplete
                          freeSolo
                          loading={getCustomersLoading}
                          loadingText={
                            <>
                              <Skeleton />
                              <Skeleton />
                              <Skeleton />
                            </>
                          }
                          inputValue={field.value}
                          options={usersList}
                          getOptionLabel={(option) =>
                            `${option.businessName} (${option.navisionId})`
                          }
                          onChange={(_event, value) => {
                            const currentValue = !value ? "" : value;
                            field.onChange(currentValue.navisionId);
                          }}
                          onInputChange={(e, newInputValue) => {
                            if (newInputValue.length === 0) {
                              setUsersList([]);
                            }
                            const valueMinCharacters = newInputValue.length > 0;
                            valueMinCharacters &&
                              debouncedCustomers(newInputValue);
                          }}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                label="Cerca Utente"
                                error={!!errors?.user?.message}
                                helperText={errors?.user?.message}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {getCustomersLoading ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            );
                          }}
                          renderOption={(props, option: any) => {
                            return (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                <span>{`${option.businessName} (${option.navisionId})`}</span>
                              </Box>
                            );
                          }}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid size={100}>
                  <Controller
                    name="proposalId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <TextField
                          variant="outlined"
                          label={"Inserisci ID Preventivo"}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          helperText={errors?.proposalId?.message}
                          error={!!errors?.proposalId?.message}
                          sx={{ marginTop: "15px", marginBottom: "10px" }}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid size={100}>
                  <Controller
                    name="isCommercialQuote"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={field.value}
                                onChange={(event) => {
                                  field.onChange(event.target.checked);
                                }}
                              />
                            }
                            label="Preventivo richiesto da Commerciale"
                          />
                          {!!errors?.isCommercialQuote?.message && (
                            <FormHelperText>
                              {errors?.isCommercialQuote?.message}
                            </FormHelperText>
                          )}
                        </>
                      );
                    }}
                  />
                </Grid>
                <Grid size={100}>
                  <Controller
                    name="IsNoCirculationDeclarationSupplied"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={field.value}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    setValue("newRegisteredVehicle", false);
                                    setNewRegisteredVehicleDisabled(true);
                                  } else {
                                    setNewRegisteredVehicleDisabled(false);
                                  }
                                  field.onChange(event.target.checked);
                                }}
                              />
                            }
                            label="Fornita dichiarazione di mancata circolazione"
                            disabled={
                              IsNoCirculationDeclarationSuppliedDisabled
                            }
                          />
                          {!!errors?.IsNoCirculationDeclarationSupplied
                            ?.message && (
                            <FormHelperText>
                              {
                                errors?.IsNoCirculationDeclarationSupplied
                                  ?.message
                              }
                            </FormHelperText>
                          )}
                        </>
                      );
                    }}
                  />
                </Grid>
                <Grid size={100}>
                  <Controller
                    name="newRegisteredVehicle"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={field.value}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    setValue(
                                      "IsNoCirculationDeclarationSupplied",
                                      false
                                    );
                                    setIsNoCirculationDeclarationSuppliedDisabled(
                                      true
                                    );
                                  } else {
                                    setIsNoCirculationDeclarationSuppliedDisabled(
                                      false
                                    );
                                  }
                                  field.onChange(event.target.checked);
                                }}
                              />
                            }
                            label="Veicolo di nuova immatricolazione"
                            disabled={newRegisteredVehicleDisabled}
                          />
                          {!!errors?.newRegisteredVehicle?.message && (
                            <FormHelperText>
                              {errors?.newRegisteredVehicle?.message}
                            </FormHelperText>
                          )}
                        </>
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Button onClick={handleSubmit(onSubmit)} variant="contained">
                Avanti
              </Button>
            </form>
          </StyledImportQuoteform>
        )}
        <Dialog
          onClose={() =>
            setShowWarningModal({
              show: false,
              content: showWarningModal.content,
            })
          }
          aria-labelledby="customized-dialog-title"
          open={showWarningModal.show}
        >
          <DialogTitle>Attenzione!</DialogTitle>
          <DialogContent>
            <DialogContentText>{showWarningModal.content}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowWarningModal({
                  show: false,
                  content: showWarningModal.content,
                });
              }}
            >
              Annulla
            </Button>
            <Button
              onClick={() => {
                navigate(
                  `/admin-importa-preventivo-dati?navisionId=${getValues(
                    "user"
                  )}&beneProposalId=${getValues(
                    "proposalId"
                  )}&isCommercialQuote=${getValues(
                    "isCommercialQuote"
                  )}&IsNoCirculationDeclarationSupplied=${getValues(
                    "IsNoCirculationDeclarationSupplied"
                  )}`
                );
              }}
              variant="contained"
              style={{ color: "white" }}
            >
              Conferma
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </>
  );
};
export default ImportQuote;
