import { Stack, Alert } from "@mui/material";

interface IProps {
  status: "empty" | "error" | "success";
  label?: string;
}

const StratusMessage = ({ status, label }: IProps) => {
  if (status === "empty") {
    return (
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert severity="warning">
          {label ? label : "Non ci sono risultati"}
        </Alert>
      </Stack>
    );
  }
  if (status === "error") {
    return (
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert severity="error">{label}</Alert>
      </Stack>
    );
  }
  if (status === "success") {
    return (
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert severity="success">{label}</Alert>
      </Stack>
    );
  }
  return null;
};

export default StratusMessage;
