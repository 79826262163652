import { useMemo } from "react";
import { AppBar, LoadingIndicator, usePermissions } from "react-admin";
import { styled } from "@mui/system";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { ListItemIcon, ListItemText, ListItemButton } from "@mui/material";
import CustomUserMenu from "./CustomUserMenu";

const StyledReportContainer = styled("div")(({ theme }) => ({
  svg: {
    fill: "white",
  },
  ".MuiListItemIcon-root": {
    minWidth: "auto",
    paddingRight: "5px",
  },
  ".MuiListItemText-root": {
    margin: "0",
  },
  ".MuiTypography-root": {
    fontSize: "15px",
  },
}));

const CustomAppBar = () => {
  const { permissions } = usePermissions();

  const showReport = useMemo(() => {
    if (permissions?.permissions?.report?.includes("read")) {
      return true;
    }
    return false;
  }, [permissions]);

  const ToolbarComponent = () => {
    const handleOnReportClick = () => {
      window.open(
        "https://app.powerbi.com/reportEmbed?reportId=c2ff61c6-2ba0-4598-a67b-e6a38c8e79e1&autoAuth=true&ctid=304a43c3-ae4a-4ec8-9cb0-637655bd0d2b",
        "_blank",
        "noreferrer"
      );
    };

    if (showReport) {
      return (
        <StyledReportContainer>
          <ListItemButton
            onClick={() => {
              handleOnReportClick();
            }}
          >
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Report" />
          </ListItemButton>
        </StyledReportContainer>
      );
    }
    return null;
  };

  return (
    <AppBar
      color="primary"
      userMenu={<CustomUserMenu />}
      toolbar={
        <>
          <LoadingIndicator />
          <ToolbarComponent />
        </>
      }
    />
  );
};

export default CustomAppBar;
