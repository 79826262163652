import { fetchUtils } from "react-admin";
import {
  combineDataProviders,
  // addRefreshAuthToDataProvider,
} from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import { API_BASE_URL, IApimValues } from "./Utils";

const httpClient = (url, options: any = {}) => {
  const accessToken = sessionStorage.getItem(`authToken`);

  if (!options.headers) {
    options.headers = new Headers({
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    });
  }

  options.headers.set("X-Custom-Header", "foobar");
  return fetchUtils.fetchJson(url, options);
};

const policiesDataProvider = jsonServerProvider(
  `${API_BASE_URL(IApimValues.mainpolicyapi)}`,
  httpClient
);

const quotesDataProvider = jsonServerProvider(
  `${API_BASE_URL(IApimValues.mainpolicyapi)}`,
  httpClient
);

const aniaDataExtractionProvider = jsonServerProvider(
  `${API_BASE_URL(IApimValues.vehicle)}/AniaDataExtraction`,
  httpClient
);

const DataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case "policies":
      return policiesDataProvider;
    case "Data":
      return aniaDataExtractionProvider;
    case "quotes":
      return quotesDataProvider;
    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
});

export default DataProvider;

// const refreshAuth = () => {
//   const accessToken = sessionStorage.getItem(`authToken`);
//   if (!accessToken) {
//     GetAuthToken().then((token) => {
//       return Promise.resolve();
//     });
//   }
//   // if (accessToken.exp < Date.now().getTime() / 1000) {
//   //   // This function will fetch the new tokens from the authentication service and update them in localStorage
//   //   return refreshAuthTokens(refreshToken);
//   // }
//   return Promise.resolve();
// };

// export const dataProvider = addRefreshAuthToDataProvider(
//   baseDataProvider,
//   refreshAuth
// );
