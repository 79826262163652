import { useMemo, useEffect } from "react";
import { Menu, usePermissions, useRedirect } from "react-admin";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import FeedIcon from "@mui/icons-material/Feed";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

const CustomPageMenu = () => {
  const { permissions } = usePermissions();
  const redirect = useRedirect();

  const showDataExtraction = useMemo(() => {
    if (permissions?.permissions?.aniaExtraction?.includes("execute")) {
      return true;
    }
    return false;
  }, [permissions]);

  const showPolicies = useMemo(() => {
    if (
      permissions?.permissions?.policy?.includes("read") &&
      process.env.REACT_APP_API_ENV === "dev"
    ) {
      return true;
    }
    return false;
  }, [permissions]);

  const showQuotes = useMemo(() => {
    if (
      permissions?.permissions?.quote?.includes("read") &&
      process.env.REACT_APP_API_ENV === "dev"
    ) {
      return true;
    }
    return false;
  }, [permissions]);

  const showImportQuotes = useMemo(() => {
    if (
      permissions?.permissions?.quote?.includes("read") &&
      permissions?.permissions?.quote?.includes("write") &&
      process.env.REACT_APP_API_ENV === "dev"
    ) {
      return true;
    }
    return false;
  }, [permissions]);

  useEffect(() => {
    if (process.env.REACT_APP_API_ENV !== "dev") {
      redirect("/ania-data-extraction");
    }
  }, [redirect]);

  return (
    <Menu>
      {showPolicies && (
        <Menu.Item
          to="/policies"
          primaryText="Polizze"
          leftIcon={<ReceiptLongIcon />}
        />
      )}
      {showQuotes && (
        <Menu.Item
          to="/quotes"
          primaryText="Preventivi"
          leftIcon={<FeedIcon />}
        />
      )}
      {showImportQuotes && (
        <Menu.Item
          to="/import-quote"
          primaryText="Importa Preventivo"
          leftIcon={<SystemUpdateAltIcon />}
        />
      )}
      {showDataExtraction && (
        <Menu.Item
          to="/ania-data-extraction"
          primaryText="Estrazione dati ANIA"
          leftIcon={<DataUsageIcon />}
        />
      )}
    </Menu>
  );
};

export default CustomPageMenu;
