import { useEffect, useState } from "react";
import { fetchCall, TypeEnumsValues, API_BASE_URL, IApimValues } from "Utils";

const beneEnumerations = [
  TypeEnumsValues.powerSupply,
  TypeEnumsValues.antiTheftDevice,
  TypeEnumsValues.transportType,
  TypeEnumsValues.fractionationType,
  TypeEnumsValues.beneVehicleType,
  TypeEnumsValues.specialUseType,
  TypeEnumsValues.genderType,
];
const aniaEnumerations = [TypeEnumsValues.aniaVehicleType];
const faiEnumerations = [
  TypeEnumsValues.dangerousGoodsType,
  TypeEnumsValues.legalForm,
];

const getEnumerationsFolder = (type: TypeEnumsValues) => {
  if (beneEnumerations.includes(type)) {
    return "BeneEnumerations";
  }
  if (aniaEnumerations.includes(type)) {
    return "AniaEnumerations";
  }
  if (faiEnumerations.includes(type)) {
    return "FaiEnumerations";
  }
};

export const useEnums = (type: TypeEnumsValues, productType?: string) => {
  const [data, setDataEnums] = useState(null);
  const [error, setErrorEnums] = useState(false);
  const [loading, setLoadingEnums] = useState(false);

  const parameter = () => {
    if (productType) {
      return `?productType=${productType}`;
    }
    return ``;
  };

  useEffect(() => {
    setLoadingEnums(true);
    fetchCall({
      method: "GET",
      URL: `${API_BASE_URL(IApimValues.utilities)}/${getEnumerationsFolder(
        type
      )}/${type}${parameter()}`,
    })
      .then((data) => {
        if (data.error) {
          setErrorEnums(true);
          setLoadingEnums(false);
        } else {
          setDataEnums(data);
          setLoadingEnums(false);
        }
      })
      .catch(() => {
        setErrorEnums(true);
        setLoadingEnums(false);
      });
  }, []);

  return { data, error, loading };
};
