import { useState } from "react";
import {
  DatagridConfigurable,
  TextField,
  List,
  FunctionField,
  DateField,
  SearchInput,
  ExportButton,
} from "react-admin";
import { Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { createAndExportXlsFile } from "./CreateAndExportXlsFile";
import { TopToolbarFixed } from "../../StyledUtils";
import { StyledReminderTitle } from "./StyledDataExtraction";
import { StatusMessage } from "Components";

export const DataExtractionLabelHeading = [
  [
    "Targa",
    "Proprietario",
    "Intestatario Polizza",
    "Avente diritto",
    "Compagnia",
    "Data effetto ultima copertura",
    "Data scadenza polizza",
    "Data scadenza copertura",
    "Provincia proprietario",
    "Tipo polizza",
    "Tipo veicolo",
    "Peso motrice",
    "Massa trainabile",
    "Data immatricolazione",
    "Tipo uso veicolo",
  ],
];

const DataExtractionLabel = DataExtractionLabelHeading[0];

const CancelDataExtraction = ({ handleOnCancelClick }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        component="button"
        role={undefined}
        color="warning"
        onClick={() => handleClickOpen()}
        startIcon={<OpenInNewIcon />}
        style={{ padding: "2px 5px" }}
      >
        Nuova estrazione dati
      </Button>
      {open && (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle>Attenzione!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Se dedici di andare avanti perderai i dati che stai visualizzando.
              <br />
              Ricordati di esportare il file!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
            >
              Annulla
            </Button>
            <Button
              onClick={() => {
                handleOnCancelClick();
              }}
              variant="contained"
              style={{ color: "white" }}
            >
              Avanti
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const DataExtractionList = ({ handleOnCancelClick }) => {
  const ListActions = () => (
    <TopToolbarFixed>
      <CancelDataExtraction
        handleOnCancelClick={() => {
          handleOnCancelClick();
        }}
      />
      <ExportButton maxResults={5000} />
    </TopToolbarFixed>
  );

  const policiesFilters = [
    <SearchInput source="q" alwaysOn resettable placeholder="Cerca Targa" />,
  ];

  return (
    <>
      <StyledReminderTitle>
        Esporta il Documento per visualizzare tutti i dettagli.
      </StyledReminderTitle>
      <List
        empty={<StatusMessage status="empty" />}
        actions={<ListActions />}
        filters={policiesFilters}
        sort={{ field: "", order: "ASC" }}
        title={"Estrazione dati Ania"}
        exporter={(data) =>
          createAndExportXlsFile({ data, fileName: "Data.xlsx" })
        }
      >
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField
            source="plate"
            label={`${DataExtractionLabel[0]}`}
            sortBy="plate"
          />
          <TextField
            source="ownerTaxCode"
            label={`${DataExtractionLabel[1]}`}
            sortable={false}
          />
          <TextField
            source="contractorTaxCode"
            label={`${DataExtractionLabel[2]}`}
            sortable={false}
          />
          <TextField
            source="beneficiaryTaxCode"
            label={`${DataExtractionLabel[3]}`}
            sortable={false}
          />
          <TextField
            source="originCompanyName"
            label={`${DataExtractionLabel[4]}`}
            sortable={false}
          />
          <DateField
            source="latestCoverageEffectDate"
            label={`${DataExtractionLabel[5]}`}
            sortable={false}
          />
          <DateField
            source="latestPolicyExpirationDate"
            label={`${DataExtractionLabel[6]}`}
            sortable={false}
          />
          <DateField
            source="latestCoverageExpirationDate"
            label={`${DataExtractionLabel[7]}`}
            sortable={false}
          />
          <TextField
            source="ownerProvinceInitials"
            label={`${DataExtractionLabel[8]}`}
            sortable={false}
          />
          <TextField
            source="policyType"
            label={`${DataExtractionLabel[9]}`}
            sortable={false}
          />
          <TextField
            source="mctcVehicleType"
            label={`${DataExtractionLabel[10]}`}
            sortable={false}
          />
          <TextField
            source="weight"
            label={`${DataExtractionLabel[11]}`}
            sortable={false}
          />
          <TextField
            source="towableMass"
            label={`${DataExtractionLabel[12]}`}
            sortable={false}
          />
          <DateField
            source="registrationDate"
            label={`${DataExtractionLabel[13]}`}
            sortable={false}
          />
          <TextField
            source="useCode"
            label={`${DataExtractionLabel[14]}`}
            sortable={false}
          />
          <TextField
            source="accidents.insuredYearsSum"
            label="Anni osservati"
            sortable={false}
          />
          <TextField
            source="accidents.accidentsSum"
            label="Sinistri osservati"
            sortable={false}
          />
          <FunctionField
            label="Ultimi 11 anni di sinistri"
            sortable={false}
            render={() => {
              return (
                <span>
                  <strong>
                    Esporta il Documento per visualizzare tutti i dettagli.
                  </strong>
                </span>
              );
            }}
          />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default DataExtractionList;
