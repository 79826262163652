import { styled } from "@mui/material/styles";
import { TopToolbar } from "react-admin";

export const TopToolbarFixed = styled(TopToolbar)(() => ({
  position: "fixed",
  right: "0",
}));

export const StyledContractorWrapper = styled("div")(() => ({
  textTransform: "capitalize",
}));
