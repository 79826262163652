import { Navigate } from "react-router-dom";

interface IProps {
  showPage: boolean | undefined;
  component: any;
}

const ProtectedRoute = ({ showPage, component }: IProps) => {
  if (showPage) {
    return component;
  }

  return <Navigate to="/" />;
};

export default ProtectedRoute;
