import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

const StyledLoadingWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "100px",
  paddingBottom: "100px",

  ".MuiCircularProgress-root": {
    width: "60px !important",
    height: "60px !important",
  },
}));

const Loader = () => {
  return (
    <StyledLoadingWrapper>
      <CircularProgress />
    </StyledLoadingWrapper>
  );
};

export default Loader;
