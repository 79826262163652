import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { DataExtractionLabelHeading } from "./DataExtractionList";
import { dateFormat } from "../../Utils";

export const createAndExportXlsFile = ({ data, fileName }) => {
  const flatData = data.map((item) => {
    const insuredYearsSum = {
      "Anni osservati": item?.accidents?.insuredYearsSum,
    };
    const accidentsSum = {
      "Sinistri osservati": item?.accidents?.accidentsSum,
    };

    item.latestCoverageEffectDate = dayjs(item.latestCoverageEffectDate).format(
      dateFormat
    );
    item.latestCoverageExpirationDate = dayjs(
      item.latestCoverageExpirationDate
    ).format(dateFormat);
    item.latestPolicyExpirationDate = dayjs(
      item.latestPolicyExpirationDate
    ).format(dateFormat);
    item.registrationDate = dayjs(item.registrationDate).format(dateFormat);

    const accidentsDetails = () => {
      return Object.keys(item?.accidents?.accidentsDetails).reduce((acc, k) => {
        acc[k.replace("Accidents", "Sinistri")] =
          item?.accidents?.accidentsDetails[k];
        return acc;
      }, {});
    };

    return {
      ...item,
      ...insuredYearsSum,
      ...accidentsSum,
      ...accidentsDetails(),
    };
  });

  const cleanFlatData = flatData.map((item) => {
    delete item.id;
    delete item.accidents;
    return item;
  });

  const worksheet = XLSX.utils.json_to_sheet(cleanFlatData);
  XLSX.utils.sheet_add_aoa(worksheet, DataExtractionLabelHeading, {
    origin: "A1",
  });

  // rename first column label
  worksheet.A1.v = "Targa";
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

  // columns width
  let wscols = [];
  cleanFlatData.forEach((obj) => {
    Object.keys(obj).forEach(function (key) {
      // @ts-ignore
      wscols.push({ wch: key.length + 30 });
    });
  });
  worksheet["!cols"] = wscols;

  // create an XLSX file and  save to name.xlsx
  XLSX.writeFile(workbook, fileName, { compression: true });
};
