import { useEffect, useState, useMemo, useRef } from "react";
import { Resource, usePermissions } from "react-admin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataExtractionList from "./DataExtractionList";
import DataExtractionProgress from "./DataExtractionProgress";
import DataExtractionEmpty from "./DataExtractionEmpty";
import Loader from "../../Components/Loading";
import {
  ExportDataStatusEnum,
  fetchCall,
  API_BASE_URL,
  IApimValues,
} from "../../Utils";

const DataExtraction = () => {
  const { permissions } = usePermissions();
  const render = useRef(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<ExportDataStatusEnum | null>(null);
  const [progressData, setProgressData] = useState<any>(null);

  const showDataExtraction = useMemo(() => {
    if (permissions?.permissions?.aniaExtraction?.includes("execute")) {
      return true;
    }
    return false;
  }, [permissions]);

  const handleOnCancelClick = () => {
    setLoading(true);
    fetchCall({
      method: "DELETE",
      URL: `${API_BASE_URL(IApimValues.vehicle)}/AniaDataExtraction/Cancel`,
      cleanResponse: true,
    })
      .then((data) => {
        if (data.error) {
          setLoading(false);
          toast.error(
            data.errorDetails?.detail
              ? `${data.errorDetails?.detail}`
              : "Si è verificato un errore",
            {
              position: "bottom-right",
              type: "error",
              autoClose: 5000,
              theme: "colored",
            }
          );
        } else {
          setStatus(null);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleOnConfirmExtratData = () => {
    setStatus(ExportDataStatusEnum.progress);
  };

  const handleOnDataExtractionCompleted = () => {
    setStatus(ExportDataStatusEnum.success);
  };

  const handleOnUpdateProgressData = (data) => {
    setProgressData({
      workedRecordsCount: data?.workedRecordsCount,
      remainingRecordsCount: data?.remainingRecordsCount,
      totalRecordsCount: data?.totalRecordsCount,
    });
  };

  const renderStatusComponent = useMemo(() => {
    if (status === ExportDataStatusEnum.empty) {
      return (
        <DataExtractionEmpty
          handleOnConfirmExtratData={handleOnConfirmExtratData}
          handleOnCancelClick={handleOnCancelClick}
          status={status}
          handleOnUpdateProgressData={handleOnUpdateProgressData}
        />
      );
    }
    if (status === ExportDataStatusEnum.toBeConfirmed) {
      return (
        <DataExtractionEmpty
          handleOnConfirmExtratData={handleOnConfirmExtratData}
          handleOnCancelClick={handleOnCancelClick}
          status={status}
          handleOnUpdateProgressData={handleOnUpdateProgressData}
        />
      );
    }
    if (status === ExportDataStatusEnum.progress) {
      return (
        <DataExtractionProgress
          handleOnCancelClick={handleOnCancelClick}
          handleOnDataExtractionCompleted={handleOnDataExtractionCompleted}
          progressData={progressData}
        />
      );
    }
    if (status === ExportDataStatusEnum.success) {
      return (
        <Resource
          name="Data"
          list={
            <DataExtractionList handleOnCancelClick={handleOnCancelClick} />
          }
        />
      );
    }

    return (
      <DataExtractionEmpty
        handleOnConfirmExtratData={handleOnConfirmExtratData}
        handleOnCancelClick={handleOnCancelClick}
        status={status}
        handleOnUpdateProgressData={handleOnUpdateProgressData}
      />
    );
  }, [progressData, status]);

  useEffect(() => {
    if (!render.current) {
      setLoading(true);
      fetchCall({
        method: "GET",
        URL: `${API_BASE_URL(IApimValues.vehicle)}/AniaDataExtraction/State`,
      })
        .then((data) => {
          if (data.error) {
            setLoading(false);
            toast.error(
              data.errorDetails?.detail
                ? `${data.errorDetails?.detail}`
                : "Si è verificato un errore",
              {
                position: "bottom-right",
                type: "error",
                autoClose: 5000,
                theme: "colored",
              }
            );
          } else {
            setStatus(data.state);
            if (data.state === ExportDataStatusEnum.progress) {
              setProgressData({
                workedRecordsCount: data?.workedRecordsCount,
                remainingRecordsCount: data?.remainingRecordsCount,
                totalRecordsCount: data?.totalRecordsCount,
              });
            }
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
    render.current = true;
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (showDataExtraction) {
    return renderStatusComponent;
  }

  return null;
};

export default DataExtraction;
