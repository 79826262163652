import { memo, useEffect, useMemo, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { usePermissions } from "react-admin";
import Loader from "../../Components/Loading";
import { fetchCall, API_BASE_URL, IApimValues } from "../../Utils";
import ProductDetailsTemplate from "./PolicyDetailTemplate";

const PolicyDetail = memo(function PolicyDetail() {
  const { permissions } = usePermissions();
  const { product, id } = useParams();
  const render = useRef(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [policyData, setPolicyData] = useState(null);

  const showPolicies = useMemo(() => {
    if (
      permissions?.permissions?.policy?.includes("read") &&
      process.env.REACT_APP_API_ENV === "dev"
    ) {
      return true;
    }
    return false;
  }, [permissions]);

  useEffect(() => {
    if (product && id && !render.current) {
      fetchCall({
        method: "GET",
        URL: `${API_BASE_URL(IApimValues.mainpolicyapi)}/${product}/${id}`,
      })
        .then((data) => {
          if (data.error) {
            setLoading(false);
            toast.error(
              data.errorDetails?.detail
                ? `${data.errorDetails?.detail}`
                : "Si è verificato un errore",
              {
                position: "bottom-right",
                type: "error",
                autoClose: 5000,
                theme: "colored",
              }
            );
          } else {
            setPolicyData(data);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
      render.current = true;
    }
  }, [product, id]);

  if (loading) {
    return <Loader />;
  }

  if (showPolicies && policyData) {
    return <ProductDetailsTemplate policyData={policyData} />;
  }

  return null;
});

export default PolicyDetail;
