import { useMemo, memo } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  List,
  DatagridConfigurable,
  DateField,
  BooleanField,
  TextField,
  FunctionField,
  usePermissions,
  SearchInput,
  SelectColumnsButton,
  ExportButton,
  BulkExportButton,
} from "react-admin";
import { FaiProductMap, FormatMoney, getProductPath } from "../../Utils";
import { TopToolbarFixed, StyledContractorWrapper } from "../../StyledUtils";
import { StatusMessage } from "Components";

const PoliciesList = memo(function PoliciesList() {
  const { permissions } = usePermissions();
  const navigate = useNavigate();

  const showPolicies = useMemo(() => {
    if (
      permissions?.permissions?.policy?.includes("read") &&
      process.env.REACT_APP_API_ENV === "dev"
    ) {
      return true;
    }
    return false;
  }, [permissions]);

  const policiesFilters = [<SearchInput source="q" alwaysOn resettable />];

  const ListActions = () => (
    <TopToolbarFixed>
      <SelectColumnsButton />
      <ExportButton />
    </TopToolbarFixed>
  );

  const BulkActions = () => (
    <>
      <BulkExportButton />
    </>
  );

  const handleOnPolicyDetail = (productType, id) => {
    navigate(`/policies/${getProductPath(productType)}/${id}`);
  };

  if (showPolicies) {
    return (
      <>
        <List
          empty={<StatusMessage status="empty" />}
          filters={policiesFilters}
          sort={{ field: "productType", order: "ASC" }}
          actions={<ListActions />}
        >
          <DatagridConfigurable
            bulkActionButtons={<BulkActions />}
            omit={["contractor.firstName", "contractor.lastName"]}
          >
            <TextField source="id" label="Id" />
            <TextField
              source="contractor.companyName"
              label="Ragione Sociale"
              sortBy="companyName"
            />
            <FunctionField
              label="Nome Contraente"
              source="contractor.lastName"
              sortBy="fullname"
              render={(record) => {
                const fullName = `${record.contractor.firstName} ${record.contractor.lastName}`;
                const fullNameCapitalized = fullName.toLowerCase();
                return (
                  <StyledContractorWrapper>
                    {fullNameCapitalized}
                  </StyledContractorWrapper>
                );
              }}
            />
            <TextField
              source="contractor.email"
              label="Email Contraente"
              sortBy="email"
            />
            <FunctionField
              label="Prodotto"
              sortBy="productType"
              render={(record) => {
                return `${FaiProductMap[record.productType]}`;
              }}
            />
            <DateField source="creationDate" label="Data di creazione" />
            <DateField source="effectiveDate" label="Data di decorrenza" />
            <DateField source="expirationDate" label="Data di scadenza" />
            <FunctionField
              label="Premio annuale"
              sortBy="annualpremium"
              render={(record) => {
                return `${FormatMoney(Number(record.annualPremium), 2)}€`;
              }}
            />
            <BooleanField source="isSigned" label="Firma" sortable={false} />
            <FunctionField
              label="Dettaglio"
              render={(record) => {
                return (
                  <Button
                    variant="text"
                    startIcon={<VisibilityIcon />}
                    onClick={() =>
                      handleOnPolicyDetail(record.productType, record.id)
                    }
                  >
                    DETTAGLIO
                  </Button>
                );
              }}
            />
          </DatagridConfigurable>
        </List>
      </>
    );
  }

  return null;
});

export default PoliciesList;
