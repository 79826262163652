import { Route } from "react-router-dom";
import { QueryClient } from "react-query";
import { Admin, Resource, CustomRoutes } from "react-admin";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import FeedIcon from "@mui/icons-material/Feed";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { PoliciesList, PolicyDetail } from "./Pages/Policies";
import { QuotesList, QuotesDetail } from "./Pages/Quotes";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import Login from "./Auth/Login";
import GetAuthProvider from "./Auth/AuthProvider";
import CustomLayout from "./Layout/CustomLayout";
import { i18nProvider } from "./i18nProvider";
import DataProvider from "./DataProvider";
import GetAuthToken from "./Auth/GetAuthToken";
import DataExtraction from "./Pages/DataExtraction/DataExtraction";
import { ProtectedRoute } from "./Components";
import ImportQuote from "./Pages/ImportQuote/ImportQuote";

declare module "@mui/material/styles" {
  interface Palette {
    gray: Palette["primary"];
  }
  interface PaletteOptions {
    gray?: PaletteOptions["primary"];
  }
}

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#035e7e",
    },
    secondary: {
      main: "#afca0b",
    },
    gray: {
      main: "#787878",
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <ToastContainer />
        <GetAuthToken />
        <Admin
          layout={CustomLayout}
          i18nProvider={i18nProvider}
          dataProvider={DataProvider}
          authProvider={GetAuthProvider()}
          queryClient={queryClient}
          theme={muiTheme}
        >
          {(permissions) => {
            const showPolicies =
              permissions?.permissions?.policy?.includes("read") &&
              process.env.REACT_APP_API_ENV === "dev";
            const showQuotes =
              permissions?.permissions?.quote?.includes("read") &&
              process.env.REACT_APP_API_ENV === "dev";
            const showDataExtraction =
              permissions?.permissions?.aniaExtraction?.includes("execute");
            const showImportQuotes =
              permissions?.permissions?.quote?.includes("read") &&
              permissions?.permissions?.quote?.includes("write") &&
              process.env.REACT_APP_API_ENV === "dev";
            return (
              <>
                {showPolicies && (
                  <Resource name="policies" list={PoliciesList} />
                )}
                {showQuotes && (
                  <Resource
                    name="quotes"
                    list={QuotesList}
                    show={QuotesDetail}
                  />
                )}
                <CustomRoutes>
                  <Route
                    path="/ania-data-extraction"
                    element={
                      <ProtectedRoute
                        showPage={showDataExtraction}
                        component={<DataExtraction />}
                      />
                    }
                  />
                  <Route
                    path="/policies/:product/:id"
                    element={
                      <ProtectedRoute
                        showPage={showPolicies}
                        component={<PolicyDetail />}
                      />
                    }
                  />
                  <Route
                    path="/import-quote"
                    element={
                      <ProtectedRoute
                        showPage={showImportQuotes}
                        component={<ImportQuote />}
                      />
                    }
                  />
                </CustomRoutes>
              </>
            );
          }}
        </Admin>
      </AuthenticatedTemplate>
    </MuiThemeProvider>
  );
};

export default App;
